export const Actions = {
  SIDEBAR: {
    UPDATE_VISIBILITY: 'UPDATE_VISIBILITY',
    UPDATE_TOGGLE: 'UPDATE_TOGGLE'
  },
  USER_DETAILS: {
    UPDATE_USERNAME: 'UPDATE_USERNAME',
    UPDATE_ROLES: 'UPDATE_ROLES',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    UPDATE_IS_AUTHENTICATED: 'UPDATE_IS_AUTHENTICATED',
    UPDATE_USER: 'UPDATE_USER',
    LOG_OUT_USER: 'LOG_OUT_USER'
  },
  MASTER_DATA: {
    SET_AIRPORTS: 'SET_AIRPORTS',
    SET_IMPORT_LOCATIONS: 'SET_IMPORT_LOCATIONS',
    SET_EXPORT_LOCATIONS: 'SET_EXPORT_LOCATIONS',
    SET_WAREHOUSES: 'SET_WAREHOUSES',
    SET_IMPORTERS: 'SET_IMPORTERS',
    SET_EXPORTERS: 'SET_EXPORTERS',
    SET_POSSIBLE_LANE_COMBINATIONS: 'SET_POSSIBLE_LANE_COMBINATIONS',
    SET_LOADINGUNIT_DESTINATIONS: 'SET_LOADINGUNIT_DESTINATIONS',
    SET_LOADINGUNIT_LMCS: 'SET_LOADINGUNIT_LMCS',
    SET_LOADINGUNIT_DESTINATION_LMC_COMBINATION:
      'SET_LOADINGUNIT_DESTINATION_LMC_COMBINATION',
    SET_CITIES: 'SET_CITIES',
    SET_LANES: 'SET_LANES',
    SET_DESTINATION: 'SET_DESTINATION'
  },
  ORDER_LINK: {
    SET_ORDER_ID_FILTER: 'SET_ORDER_ID_FILTER',
    SET_LABEL_ID_FILTER: 'SET_LABEL_ID_FILTER',
    CLEAR_ORDER_ID_FILTER: 'CLEAR_ORDER_ID_FILTER',
    CLEAR_LABEL_ID_FILTER: 'CLEAR_LABEL_ID_FILTER'
  },
  TIME_ZONE: {
    SET_TIME_ZONE: 'SET_TIME_ZONE'
  }
}
